import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import get from 'lodash/get';
import { getReports } from 'api/alpha/reports';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { datetimeFormatter } from 'utils/datetime';
import Button from 'components/Button';
import MKTypography from 'components/MaterialKit/MKTypography';
import { getLocaleMap } from 'utils/locales';

const ReportsListViewSection = () => {
  const [localeMapping, setLocaleMapping] = useState({});

  useEffect(() => {
    getLocaleMap(['download'])
      .then((response) => {
        setLocaleMapping(response);
      });
  }, []);

  const { setAuth } = useAuth();
  const [sortBy, setSortBy] = useState('createddate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const reportTableColumns = useMemo(() => [
    // { field: 'report_id', label: 'ID', sortable: true },
    { field: 'name', label: 'Name', sortable: true },
    { field: 'description', label: 'Description', sortable: true },
    { field: 'createddate', label: 'Generated Date', sortable: true },
    { field: 'endpoint', width: '10px' },
  ], []);

  const [reports, setReports] = useState([]);

  const fetchReportsFromApi = useCallback(() => {
    const requestParams = {
      ...(sortBy ? {
        $orderBy: `${sortBy || 'createddate'} ${sortDirection || 'desc'}`,
        $skip: currentPage * pageSize,
        $top: pageSize,
      } : {}) };
    return getReports(requestParams)
      .then(({ data, headers }) => {
        setReports(data);
        const contentRange = get(headers, 'content-range');
        const size = Number(contentRange.split('/')[1]);
        setTotalRows(size);
      })
      .catch((err) => {
        setReports([]);
        handleErrorResponse(err, setAuth);
      });
  }, [currentPage, pageSize, setAuth, sortBy, sortDirection]);

  useEffect(() => {
    fetchReportsFromApi();
  }, [fetchReportsFromApi]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = reportTableColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [reportTableColumns]);

  const rowData = useMemo(() => {
    if (!reports) return [];
    return reports.map((report) => {
      return {
        ...report,
        createddate: datetimeFormatter(report?.createddate, 'YYYY-MM-DD HH:mm:ss'),
        endpoint: (
          <a href={report.endpoint} download={report.name}>
            <Button variant="contained" size="small" color="#29ABE2">
              <MKTypography variant="body1" color="white" fontWeight="bold" fontSize="12px">
                {localeMapping.download}
              </MKTypography>
            </Button>
          </a>
        ),
      };
    }).filter(Boolean);
  }, [localeMapping.download, reports]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={reportTableColumns}
        idField="report_id"
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={[10, 20, 50]}
        totalCount={totalRows}
        onRowsPerPageChange={setPageSize}
      />
    </MKBox>
  );
};

export default ReportsListViewSection;
