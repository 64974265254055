import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { getCameras, deleteCamera } from 'api/alpha/cameras';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';
import { datetimeFormatter } from 'utils/datetime';
import HexagonIcon from '@mui/icons-material/Hexagon';
import { updateCamerasFloorPolygon, updateCamerasIP } from 'api/alpha/devices';

const CameraListViewSection = () => {
  const [sortBy, setSortBy] = useState('active');
  const [sortDirection, setSortDirection] = useState('desc');

  const cameraColumns = useMemo(() => [
    { field: 'camera_identifier', label: 'Camera Identifier', sortable: true },
    { field: 'camera_number', label: 'Camera Number', sortable: true },
    { field: 'device_physical_id', label: 'Device ID' },
    { field: 'ip', label: 'IP Address' },
    { field: 'active', label: 'Online', sortable: true },
    { field: 'lastmoddate', label: 'Last Modified Date', sortable: true },
  ], []);

  const [cameras, setCameras] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchCamerasFromApi = useCallback(() => {
    const requestParams = {
      $expand: 'patient,device',
      ...(sortBy ? {
        $orderBy: `${sortBy} ${sortDirection}`,
      } : {}) };
    return getCameras(requestParams)
      .then(({ data }) => {
        setCameras(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth, sortBy, sortDirection]);

  const onPressAdd = useCallback(() => {
    navigate('/devices/cameras/create?from=devices_list');
  }, [navigate]);

  const onPressEdit = useCallback((itemId) => {
    navigate(`/devices/cameras/edit?camera_id=${itemId}&edit=true`);
  }, [navigate]);

  const onPressDelete = useCallback((id) => {
    return deleteCamera(id)
      .then(() => {
        return fetchCamerasFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchCamerasFromApi, setAuth]);

  useEffect(() => {
    fetchCamerasFromApi();
  }, [fetchCamerasFromApi]);

  const onClickSort = useCallback((colName) => {
    const pageTableColumn = cameraColumns.find((col) => col.field === colName);
    if (pageTableColumn?.sortable) {
      setSortBy(colName);
      setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    }
  }, [cameraColumns]);

  const rowData = useMemo(() => {
    if (!cameras) return [];
    return cameras.map((camera) => {
      return {
        ...camera,
        active: camera?.active ? (<HexagonIcon sx={{ color: '#67FF49' }} />) : (<HexagonIcon sx={{ color: '#FF5549' }} />),
        device_physical_id: camera?.device?.device_physical_id,
        lastmoddate: datetimeFormatter(camera?.lastmoddate, 'YYYY-MM-DD HH:mm:ss'),
      };
    }).filter(Boolean);
  }, [cameras]);

  const onPressUpdateIP = useCallback((cameraIds) => {
    if (!cameraIds.length) return console.log('No camera selected');
    const body = {
      camera_id: cameraIds,
    };
    return updateCamerasIP(body)
      .then((response) => {
        console.log('Updated cameras', body, response);
        return fetchCamerasFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchCamerasFromApi, setAuth]);

  const onPressUpdateFloorPolygon = useCallback((cameraIds) => {
    if (!cameraIds.length) return console.log('No camera selected');
    const body = {
      camera_id: cameraIds,
    };
    return updateCamerasFloorPolygon(body)
      .then((response) => {
        console.log('Updated cameras', body, response);
        return fetchCamerasFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchCamerasFromApi, setAuth]);

  return (
    <MKBox my={2}>
      <DataTable
        data={rowData}
        columns={cameraColumns}
        idField="camera_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        onPressUpdateIP={onPressUpdateIP}
        onPressUpdateFloorPolygon={onPressUpdateFloorPolygon}
        selectable
        clickable
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
      />
    </MKBox>
  );
};

CameraListViewSection.propTypes = {
  ward: PropTypes.shape({
    ward_id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default CameraListViewSection;
